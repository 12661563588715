import { render, staticRenderFns } from "./StatusOfUtilizationByReservationChannel.vue?vue&type=template&id=21af9660&scoped=true&"
import script from "./StatusOfUtilizationByReservationChannel.vue?vue&type=script&lang=js&"
export * from "./StatusOfUtilizationByReservationChannel.vue?vue&type=script&lang=js&"
import style0 from "./StatusOfUtilizationByReservationChannel.vue?vue&type=style&index=0&id=21af9660&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21af9660",
  null
  
)

export default component.exports