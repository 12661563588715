<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                  ref="year"
                  v-model="searchOptions.year"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="isBlankTimeInclude"
                        v-model="searchOptions.isBlankTimeInclude"
                        value="true"
                      />
                      <i></i>
                      <div class="label">총팀수 숨김 포함</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
            button-div="GET"
            :is-shortcut-button="true"
            :ignore="isPopupOpened"
            @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">예약 채널별 가동율 현황 목록</div>
              <div class="header-caption">[{{numberWithCommas(statusOfUtilizationByReservationChannelsLength)}}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <ejs-button @click.native="onClickExcel">
                    Excel
                  </ejs-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                ref="monthTab"
                :items="monthTabItems"
                :showCloseButton="false"
                heightAdjustMode="Auto"
                overflowMode="Popup"
                @selected="onMonthTabSelected"
              />
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-0101">
                <div class="section-body">
                  <ejs-grid-wrapper
                    ref="bodyGrid"
                    v-bind="bodyGridProps"
                    :dataSource="statusOfUtilizationByReservationChannels"
                    :aggregates="bodyGridAggregates"
                    @queryCellInfo="onBodyGridQueryCellInfo"
                    @actionComplete="onBodyGridActionComplete"
                  />
                </div>
              </section>
            </article>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {numberWithCommas} from "@/utils/number";
import {getEndOfMonth, getTodayNavigationDate} from "@/utils/date";
import {commonCodesGetColorValue} from "@/utils/commonCodes";

export default {
  name: "StatusOfUtilizationByReservationChannel",
  components: {
    EjsGridWrapper,
    InputNumber,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      monthTabItems: [
        {
          header: {text: "01월"},
          selectedTab: "01",
        },
        {
          header: {text: "02월"},
          selectedTab: "02",
        },
        {
          header: {text: "03월"},
          selectedTab: "03",
        },
        {
          header: {text: "04월"},
          selectedTab: "04",
        },
        {
          header: {text: "05월"},
          selectedTab: "05",
        },
        {
          header: {text: "06월"},
          selectedTab: "06",
        },
        {
          header: {text: "07월"},
          selectedTab: "07",
        },
        {
          header: {text: "08월"},
          selectedTab: "08",
        },
        {
          header: {text: "09월"},
          selectedTab: "09",
        },
        {
          header: {text: "10월"},
          selectedTab: "10",
        },
        {
          header: {text: "11월"},
          selectedTab: "11",
        },
        {
          header: {text: "12월"},
          selectedTab: "12",
        }
      ],
      statusOfUtilizationByReservationChannels: [],
      statusOfUtilizationByReservationChannel: {
        resveTotBiul: null,
        resveMemberBiul: null,
        resveCoprBiul: null,
        resveAgncyBiul: null,
        resveNomBiul: null,
        visitTotBiul: null,
        visitMemberBiul: null,
        visitCoprBiul: null,
        visitAgncyBiul: null,
        visitNomBiul: null,
      },
      statusOfUtilizationByReservationChannelsLength: 0,
      searchOptions: {
        year: null,
        month: null,
        isBlankTimeInclude: false,
      },
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {},
  computed: {
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        columns: [
          {
            field: "bsnDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isPrimaryKey: true,
          },
          {
            field: "dwName",
            headerText: "요일",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "center",
          },
          {
            field: "bsnCode",
            headerText: "영업구분",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "BSN_CODE",
          },
          {
            field: "hldyName",
            headerText: "공휴일명",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            headerText: "전체",
            columns: [
              {
                headerText: "팀수",
                columns: [
                  {
                    field: "resveTotCnt",
                    headerText: "총팀수",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "resveBlankCnt",
                    headerText: "숨김팀수",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "resveResveCnt",
                    headerText: "예약팀",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "visitTotCnt",
                    headerText: "가동팀",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                field: "resveTotBiul",
                headerText: "예약율(%)",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
              },
              {
                field: "visitTotBiul",
                headerText: "가동율(%)",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "예약율",
            columns: [
              {
                headerText: "회원",
                columns: [
                  {
                    field: "resveMemberCnt",
                    headerText: "예약",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "resveMemberBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                headerText: "이용권",
                columns: [
                  {
                    field: "resveCoprCnt",
                    headerText: "예약",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "resveCoprBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                headerText: "대행사",
                columns: [
                  {
                    field: "resveAgncyCnt",
                    headerText: "예약",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "resveAgncyBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                headerText: "비회원",
                columns: [
                  {
                    field: "resveNomCnt",
                    headerText: "예약",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "resveNomBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
            ],
          },
          {
            headerText: "가동율",
            columns: [
              {
                headerText: "회원",
                columns: [
                  {
                    field: "visitMemberCnt",
                    headerText: "가동",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "visitMemberBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                headerText: "이용권",
                columns: [
                  {
                    field: "visitCoprCnt",
                    headerText: "가동",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "visitCoprBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                headerText: "대행사",
                columns: [
                  {
                    field: "visitAgncyCnt",
                    headerText: "가동",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "visitAgncyBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
              {
                headerText: "비회원",
                columns: [
                  {
                    field: "visitNomCnt",
                    headerText: "가동",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                  {
                    field: "visitNomBiul",
                    headerText: "비율(%)",
                    type: "number",
                    format: "N",
                    minWidth: 16,
                    width: 70,
                    textAlign: "right",
                  },
                ],
              },
            ],
          }
        ],
        allowExcelExport: true,
        allowSorting: false,
        allowFiltering: false,
      };
    },
    bodyGridAggregates() {
      return [
        {
          columns: [
            {
              field: "hldyName",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: Number(this.searchOptions.month) + "월 소계",
            },
            {
              field: "resveTotCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveBlankCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveResveCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "visitTotCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "visitMemberCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveTotBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.resveTotBiul,
            },
            {
              field: "resveMemberCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveMemberBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.resveMemberBiul,
            },
            {
              field: "resveCoprCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveCoprBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.resveCoprBiul,
            },
            {
              field: "resveAgncyCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveAgncyBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.resveAgncyBiul,
            },
            {
              field: "resveNomCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "resveNomBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.resveNomBiul,
            },


            {
              field: "visitTotBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.visitTotBiul,
            },
            {
              field: "visitMemberCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "visitMemberBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.visitMemberBiul,
            },
            {
              field: "visitCoprCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "visitCoprBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.visitCoprBiul,
            },
            {
              field: "visitAgncyCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "visitAgncyBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.visitAgncyBiul,
            },
            {
              field: "visitNomCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "visitNomBiul",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: this.statusOfUtilizationByReservationChannel.visitNomBiul,
            },
          ],
        },
      ];
    }
  },
  async mounted() {
    const month = await getTodayNavigationDate("MM");
    const monthTabIndex = this.monthTabItems.findIndex(tabItem => tabItem.selectedTab === month);

    if (monthTabIndex >= 0) {
      this.$refs.monthTab.select(monthTabIndex);
    }
  },
  methods: {
    numberWithCommas,
    onViewButtonClicked() {
      this.getStatusOfUtilizationByReservationChannel();
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    onMonthTabSelected(args) {
      this.searchOptions.month = this.monthTabItems[args.selectedIndex].selectedTab;

      this.getStatusOfUtilizationByReservationChannel();
    },
    async getStatusOfUtilizationByReservationChannel() {
      const fromDate = this.searchOptions.year + "-" + this.searchOptions.month + "-" + "01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), Number(this.searchOptions.month));
      const toDate = this.searchOptions.year + "-" + this.searchOptions.month + "-" + monthLastDay;

      this.statusOfUtilizationByReservationChannels = await GolfErpAPI.fetchStatusOfUtilizationByReservationChannel({
        fromDate: fromDate,
        toDate: toDate,
        isBlankTimeInclude: this.searchOptions.isBlankTimeInclude,
      });

      // syncfusion Grid에서 customAggregate function 사용하면 엑셀 저장시 데이터가 안나오는 버그 발생.
      // 예) customAggregate: (args) => { return 0; }
      // 그래서 아래와 같이 데이터 계산후 직접 지정해줘야함.
      // 예) customAggregate: this.statusOfUtilizationByReservationChannel.resveTotBiul,
      const totCnt = this.statusOfUtilizationByReservationChannels.map(data => data.resveTotCnt).reduce((acc, cur) => acc + cur, 0);
      const resveResveCnt = this.statusOfUtilizationByReservationChannels.map(data => data.resveResveCnt).reduce((acc, cur) => acc + cur, 0);
      const resveMemberCnt = this.statusOfUtilizationByReservationChannels.map(data => data.resveMemberCnt).reduce((acc, cur) => acc + cur, 0);
      const resveCoprCnt = this.statusOfUtilizationByReservationChannels.map(data => data.resveCoprCnt).reduce((acc, cur) => acc + cur, 0);
      const resveAgncyCnt = this.statusOfUtilizationByReservationChannels.map(data => data.resveAgncyCnt).reduce((acc, cur) => acc + cur, 0);
      const resveNomCnt = this.statusOfUtilizationByReservationChannels.map(data => data.resveNomCnt).reduce((acc, cur) => acc + cur, 0);

      const visitTotCnt = this.statusOfUtilizationByReservationChannels.map(data => data.visitTotCnt).reduce((acc, cur) => acc + cur, 0);
      const visitMemberCnt = this.statusOfUtilizationByReservationChannels.map(data => data.visitMemberCnt).reduce((acc, cur) => acc + cur, 0);
      const visitCoprCnt = this.statusOfUtilizationByReservationChannels.map(data => data.visitCoprCnt).reduce((acc, cur) => acc + cur, 0);
      const visitAgncyCnt = this.statusOfUtilizationByReservationChannels.map(data => data.visitAgncyCnt).reduce((acc, cur) => acc + cur, 0);
      const visitNomCnt = this.statusOfUtilizationByReservationChannels.map(data => data.visitNomCnt).reduce((acc, cur) => acc + cur, 0);

      this.statusOfUtilizationByReservationChannel.resveTotBiul = totCnt > 0 ? Math.round(resveResveCnt / totCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.resveMemberBiul = totCnt > 0 ? Math.round(resveMemberCnt / totCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.resveCoprBiul = totCnt > 0 ? Math.round(resveCoprCnt / totCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.resveAgncyBiul = totCnt > 0 ? Math.round(resveAgncyCnt / totCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.resveNomBiul = totCnt > 0 ? Math.round(resveNomCnt / totCnt * 100) : 0;

      this.statusOfUtilizationByReservationChannel.visitTotBiul = totCnt > 0 ? Math.round(visitTotCnt / totCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.visitMemberBiul = visitTotCnt > 0 ? Math.round(visitMemberCnt / visitTotCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.visitCoprBiul = visitTotCnt > 0 ? Math.round(visitCoprCnt / visitTotCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.visitAgncyBiul = visitTotCnt > 0 ? Math.round(visitAgncyCnt / visitTotCnt * 100) : 0;
      this.statusOfUtilizationByReservationChannel.visitNomBiul = visitTotCnt > 0 ? Math.round(visitNomCnt / visitTotCnt * 100) : 0;
    },
    onBodyGridActionComplete(args) {
      const {
        requestType
      } = args;

      if (["refresh", "filtering"].includes(requestType)) {
        this.statusOfUtilizationByReservationChannelsLength = args?.rows?.length || 0;
      }
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;

      if (field && (field.endsWith("Cnt") || field.toString().endsWith("Biul"))) {
        if (data[field] === 0) {
          cell.innerText = "-";
        }
      }

      if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", data[field]);
      } else if (field === "dwName") {
        cell.style.color = commonCodesGetColorValue("DW_CODE", data["dwCode"]);
      }
    }
  }
};
</script>
